/* *-----------------------------------------------------------------------------------
    CSS INDEX
    ===================
    1. Default
    2. Sidebar
    3. Header
    4. Menu Button
    5. Footer
    6. Main Content
    7. Page Heading
    8. Avatar
    9. Card
    10. Table
    11. Forms
    12. Button
    13. Switch Button 1
    14. Modal
    15. Login Screen
    16. Pagination
    17. Page Not Found
    18. Loader
    19. Sweet Alert
    20. React Switch
    21. Darkmode
/*--------------------------------------------------------------
    1. Default
--------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700&display=swap');
* {
    outline-color: transparent;
    outline-style: none;
}

:root {
    --white: #FFFFFF;
    --light: #E6ECFF;
    --light2: #E5F0FF;
    --dark: #24272C;
    --gray: #6C737D;
    --primary: #456DFF;
    --success: #34A853;
    --danger: #EA4335;
    --warning: #FFBF1C;
    --info: #4285F4;
    --purple: #8652ff;
    --border: #CED8E7;
    --border2: #CEE5FC;
    --font-white: #FFFFFF;
    --box-shadow: 0 0 6px -4px #00000014, 0 0 16px 0 #0000000d, 0 0 28px 8px #0000000d;
}

[data-theme="waitover-darks"] {
    --white: #201e26;
    --light: #141218;
    --light2: #141218;
    --dark: #b2b2b2;
    --gray: #b2b2b2;
    --primary: #456DFF;
    --success: #44CF94;
    --danger: #EA4335;
    --warning: #FFBF1C;
    --info: #4285F4;
    --purple: #8652ff;
    --border: #2e2d32;
    --border2: #2e2d32;
    --font-white: #FFFFFF;
    --box-shadow: 0 3px 6px -4px #0000006b, 0 0 16px 0 #0000002b, 0 0 28px 8px #0000004a !important;
}
html.light {
    --white: #FFFFFF;
    --light: #E6ECFF;
    --light2: #E5F0FF;
    --dark: #24272C;
    --gray: #6C737D;
    --primary: #456DFF;
    --success: #34A853;
    --danger: #EA4335;
    --warning: #FFBF1C;
    --info: #4285F4;
    --purple: #8652ff;
    --border: #EDEDED;
    --border2: #CEE5FC;
    --font-white: #FFFFFF;
}
html.dark {
    --white: #201e26;
    --light: #141218;
    --light2: #141218;
    --dark: #b2b2b2;
    --gray: #b2b2b2;
    --primary: #456DFF;
    --success: #44CF94;
    --danger: #EA4335;
    --warning: #FFBF1C;
    --info: #4285F4;
    --purple: #8652ff;
    --border: #2e2d32;
    --border2: #2e2d32;
    --font-white: #FFFFFF;
}
body {
    font-family: 'Maven Pro', sans-serif;
    color: var(--dark);
    font-size: 15px;
    background-color: var(--light2);
    overflow-x: hidden;
    scroll-behavior: smooth;
}
::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
}
::-webkit-scrollbar-track {
    background-color: transparent !important;
}
::-webkit-scrollbar-thumb {
    background: var(--gray);
    border-radius: .325rem;
}
::selection {
    color: var(--font-white);
    background-color: var(--primary);
}
::-moz-selection {
    color: var(--font-white);
    background-color: var(--primary);
}
a {
    color: var(--dark);
    text-decoration: none;
}
a:hover, a:focus{
    text-decoration: none;
}
a:hover {
    color: var(--primary);
}
a:-webkit-any-link:focus-visible {
    outline-offset: 0;
}
a:-webkit-any-link:focus-visible {
    outline-offset: unset;
}
select {
    background-color: var(--light) !important;
}
.dropdown-item{
    font-weight: 500;
    color: var(--dark);
}
.dropdown-item:focus, .dropdown-item:hover {
    color: var(--dark);
    background-color: var(--light);
}
button:focus,
.btn-close:focus,
.btn:focus-visible,
.btn:focus {
    outline: 0;
    box-shadow: unset;
}
:focus-visible {
    outline: unset;
}

:focus-visible {
    outline: 0;
}
:-webkit-direct-focus {
    outline-color: unset;
    outline-style: unset;
    outline-width: 0 !important;
}
:focus {
    outline-color: unset;
    outline-style: unset;
    outline-width: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--dark);
    background-color: var(--light);
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: unset !important;
}
.form-control::-webkit-input-placeholder,
input[type=text]::-webkit-input-placeholder,
input[type=password]::-webkit-input-placeholder,
input[type=datetime]::-webkit-input-placeholder,
input[type=datetime-local]::-webkit-input-placeholder,
input[type=date]::-webkit-input-placeholder,
input[type=month]::-webkit-input-placeholder,
input[type=time]::-webkit-input-placeholder,
input[type=week]::-webkit-input-placeholder,
input[type=number]::-webkit-input-placeholder,
input[type=email]::-webkit-input-placeholder,
input[type=url]::-webkit-input-placeholder,
input[type=search]::-webkit-input-placeholder,
input[type=tel]::-webkit-input-placeholder,
input[type=color]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #6C737D
}
html.dark .form-control::-webkit-input-placeholder,
html.dark input[type=text]::-webkit-input-placeholder,
html.dark input[type=password]::-webkit-input-placeholder,
html.dark input[type=datetime]::-webkit-input-placeholder,
html.dark input[type=datetime-local]::-webkit-input-placeholder,
html.dark input[type=date]::-webkit-input-placeholder,
html.dark input[type=month]::-webkit-input-placeholder,
html.dark input[type=time]::-webkit-input-placeholder,
html.dark input[type=week]::-webkit-input-placeholder,
html.dark input[type=number]::-webkit-input-placeholder,
html.dark input[type=email]::-webkit-input-placeholder,
html.dark input[type=url]::-webkit-input-placeholder,
html.dark input[type=search]::-webkit-input-placeholder,
html.dark input[type=tel]::-webkit-input-placeholder,
html.dark input[type=color]::-webkit-input-placeholder,
html.dark textarea::-webkit-input-placeholder {
     color: #444444
}
.text-primary{
    color: var(--primary) !important;
}
.border-bottom{
    border-color: var(--border) !important;
}
.border-start{
    border-color: var(--border) !important;
}
/*--------------------------------------------------------------
    2. Sidebar
--------------------------------------------------------------*/
.sidebar-wrapper {
    width: 250px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: var(--white);
    bottom: 0;
    transition: width 0.3s ease-out, left 0.5s ease-out;
}
/* SideBar Header*/
.sidebar-wrapper .sidebar-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    border-right: 1px solid var(--border);
}
.sidebar-wrapper .sidebar-header img {
    display: block;
    max-width: 100%;
    height: 44px;
    margin: 0 auto;
}
/* SideBar Menu*/
.sidebar-menu {
    position: relative;
    width: 250px;
    height: calc(100vh - 75px);
    background-color: var(--white);
    border-top: 1px solid var(--border);
    border-right: 1px solid var(--border);
    overflow-y: auto;
    transition: width 0.3s ease-out;
}

.sidebar-wrapper .menu {
    margin-top: 1rem;
    padding: 0;
    font-weight: 600;
}

.sidebar-wrapper .menu .sidebar-link {
    position: relative;
    display: flex;
    align-items: center;
    padding: 1rem;
    margin: 0 1rem;
    font-size: 15px;
    font-weight: 500;
    border-radius: 0.5rem;
    transition: 0.3s;
    text-decoration: none;
    color: var(--gray);
}

.sidebar-wrapper .menu .sidebar-link.active i,
.sidebar-wrapper .menu .sidebar-link.active>span {
    color: var(--primary);
}

.sidebar-wrapper .menu .sidebar-link.active{
    background-color: rgb(69 109 255 / 7%);
}
.sidebar-wrapper .menu .sidebar-link i {
    font-size: 1.25rem;
    color: var(--gray);
}

.sidebar-wrapper .menu .sidebar-link span {
    margin-left: .7rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 15ch;
}

.sidebar-wrapper .menu .sidebar-item:hover a,
.sidebar-wrapper .menu .sidebar-item:hover a i,
.sidebar-wrapper .menu .sidebar-link:hover,
.sidebar-wrapper .menu .sidebar-link:hover i {
    color: var(--primary);
}

.sidebar-wrapper .menu .sidebar-item {
    list-style: none;
    position: relative;
}

.sidebar-wrapper .menu .sidebar-item.has-sub .sidebar-link {
    cursor: pointer;
}

.sidebar-wrapper .menu .sidebar-item.has-sub .sidebar-link:after {
    content: "\ea4a";
    font-family: boxicons !important;
    position: absolute;
    color: var(--gray);
    right: 15px;
    top: 14px;
    display: block;
    font-size: 20px;
}
.sidebar-wrapper .menu .sidebar-item.active .sidebar-link:before {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 30px;
    background-color: var(--primary);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.sidebar-wrapper .menu .sidebar-item .sidebar-link.active span,
.sidebar-wrapper .menu .sidebar-item.has-sub.active .sidebar-link:after,
.sidebar-wrapper .menu .sidebar-item.has-sub:hover .sidebar-link:after {
    color: var(--primary);
}

.sidebar-wrapper .menu .sidebar-item .sidebar-link.active i,
.sidebar-wrapper .menu .sidebar-item .sidebar-link.active svg {
    fill: var(--primary);
    color: var(--primary);
}

.sidebar-wrapper .menu .sidebar-item.active .sidebar-link.has-sub:after {
    content: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-down"><path stroke="%23fff" stroke-width="1" d="M6 9l6 6 6-6"/></svg>');
}

.sidebar-wrapper .menu .submenu {
    list-style: none;
    display: none;
    transition: max-height 2s cubic-bezier(0, 0.55, 0.45, 1);
    overflow: hidden;
}

.sidebar-wrapper .menu .submenu.active {
    max-height: 999px;
    display: block;
}

.sidebar-wrapper .menu .submenu .submenu-item.active {
    position: relative;
}

.sidebar-wrapper .menu .submenu .submenu-item a.active {
    color: var(--primary);
}

.sidebar-wrapper .menu .submenu .submenu-item.active>a {
    color: var(--primary);
}

.sidebar-wrapper .menu .submenu .submenu-item a {
    padding: 0.7rem 1.4rem;
    padding-left: 2.1rem;
    display: block;
    color: var(--gray);
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.5px;
    transition: all 0.3s;
}

.sidebar-wrapper .menu .submenu .submenu-item a:hover {
    color: var(--primary);
}
.layout-wrapper .toggler {
    display: none;
}
.dark .layout-wrapper .sidebar-logo .logo{
    display: none;
}
.dark .layout-wrapper .sidebar-logo .logodark{
    display: block !important;
}
.layout-wrapper .sidebar-logo .logo-mini{
    display: none;
}
.layout-wrapper.collapsed .menu .sidebar-item.has-sub .sidebar-link:after{
    opacity: 0 
}
.layout-wrapper.collapsed .sidebar-menu:hover .sidebar-item.has-sub .sidebar-link:after{
    opacity: unset;
}
@media screen and (min-width: 1199px) {
    .layout-wrapper.collapsed .sidebar-logo .logo{
        display: none;
    }
    .layout-wrapper.collapsed .sidebar-logo .logo-mini{
        display: block;
    }
    .layout-wrapper.collapsed .sidebar-menu:hover{
        width: 250px;
    }
    .layout-wrapper .toggler {
        display: block;
        position: fixed;
        left: 265px;
        top: 23.5px;
        transition: left 0.3s ease-out;
        z-index: 99;
    }
    .layout-wrapper.collapsed .sidebar-wrapper,
    .layout-wrapper.collapsed .sidebar-menu{
        width: 85px;
    }
    .layout-wrapper.collapsed header.sidebar-header{
        left: 85px;
        width: calc(100% - 85px);
    }
    .layout-wrapper.collapsed .toggler{
        left: 100px;
    }
    .layout-wrapper.collapsed #main {
        margin-left: 85px;
    }
}
@media screen and (max-width: 1199px) {
    .sidebar-wrapper {
        position: fixed;
        left: -250px;
    }
    .sidebar-wrapper .sidebar-toggler.x {
        display: block;
    }
    .sidebar-wrapper.active {
        left: 0 !important;
    }
}
/*--------------------------------------------------------------
    3. Header
--------------------------------------------------------------*/
header.sidebar-header {
    background-color: var(--white);
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 250px;
    width: calc(100% - 250px);
    height: 75px;
    padding: 0 1.5rem;
    transition: left 0.3s ease-out;
    z-index: 9;
}
header.sidebar-header.full-header{
    width: 100%;
    left: 0;
}
.sidebar-header .header-left-menu .logo,
.sidebar-header .sidebar-logo .logo,
.sidebar-header .header-left-menu .logodark,
.sidebar-header .sidebar-logo .logodark{
    width: 200px;
    height: auto;
}
.sidebar-header .header-left-menu .logo-mini,
.sidebar-header .header-right-menu .logo-mini,
.sidebar-header .sidebar-logo .logo-mini{
    height: 44px;
    width: auto;
}
html.dark .sidebar-header .header-left-menu .logo path:first-child,
html.dark .sidebar-header .sidebar-logo .logo path:first-child{
    fill: var(--font-white);
}
html.dark .sidebar-header .header-left-menu .logo-mini path:last-child,
html.dark .sidebar-header .header-right-menu .logo-mini path:last-child,
html.dark .sidebar-header .sidebar-logo .logo-mini path:last-child{
    fill: var(--font-white);
}
header .header-right-menu{
    display: flex;
    align-items: center;
}
header .header-right-menu .dropdown .dropdown-toggle {
    display: block;
    border: none;
    padding: 0.5rem;
    background-color: var(--light);
    border-radius: 0.625rem;
    margin: 0;
    width: 100%;
    box-shadow: none;
}
header .header-right-menu .user-menu img{
    width: 44px;
    height: 44px;
    max-width: 44px;
    min-height: 44px;
    border: 1px solid var(--border);
    border-radius: .625rem;
}
header .header-right-menu .dropdown .dropdown-toggle::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    border-top: 0.3em solid var(--dark);
}

header .header-right-menu .dropdown-menu.show {
    display: block;
    margin: 1rem .625rem 1rem -.625rem!important;
    min-width: 218px !important;
    border-radius: 0.625rem;
    background-color: var(--white);
    box-shadow: 0 0 20px 3px #00000014;
    border: none;
}
header .header-right-menu .dropdown-item.active, header .header-right-menu .dropdown-item:active {
    color: var(--font-white);
    text-decoration: none;
    background-color: var(--primary);
}
header .header-right-menu .dropdown-divider {
    border-top-color: var(--border);
}

header .header-right-menu .dropdown .user-menu {
    display: flex;
    align-items: center;
    padding-right: 24px;
}

header .header-right-menu .dropdown .user-menu .user-name {
    text-align: left;
}

header .header-right-menu .dropdown .user-menu .user-name h6 {
    color: var(--dark);
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 15ch;
}

header .header-right-menu .dropdown .user-menu .user-name p {
    color: var(--gray);
    margin-bottom: 0;
    font-size: 14px;
}
@media (max-width: 1199.98px) {
    header.sidebar-header {
        left: 0;
        width: 100%;
    }
    header .header-right-menu .burger-btn{
        margin-left: .25rem;
    }
}
@media (max-width: 767.98px){
    .full-header .header-right-menu .btn {
        --bs-btn-padding-y: 0.25rem;
        --bs-btn-padding-x: 0.5rem;
        --bs-btn-font-size: 0.875rem;
        --bs-btn-border-radius: 0.25rem;
    }
}
@media (max-width: 575px) {
    header.sidebar-header{
        padding: 0 1rem;
    }
    header .header-right-menu .user-menu img {
        width: 36px;
        height: 36px;
        max-width: 36px;
        min-height: 36px;
        border: 1px solid var(--border);
        border-radius: 0.375rem;
    }
}
@media (max-width: 450px) {
    header .header-right-menu .dropdown .user-menu .user-name h6 {
        max-width: 7ch;
    }
}
@media (max-width: 350px) {
    header .header-right-menu .dropdown .user-menu .user-name{
        display: none;
    }
}
/*--------------------------------------------------------------
    4. Menu Button
--------------------------------------------------------------*/
.burger-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    color: var(--dark) !important;
    border: none;
    width: 35px;
    height: 35px;
    font-size: 22px;
    border-radius: .625rem;
}
.burger-btn.btn{
    background-color: var(--white) !important;
    color: var(--dark) !important; 
}
.burger-btn.btn i {
    color: var(--dark) !important;
}
/*--------------------------------------------------------------
    5. Footer
--------------------------------------------------------------*/
.page-footer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1rem;
    width: 100%;
    height: auto;
    background-color: var(--white);
    position: fixed;
    bottom: 0 !important;
    right: 0 !important;
}
.page-footer i {
    font-size: 18px;
    line-height: 24px;
    margin: 0 3px;
}
.page-footer a{
    color: var(--primary);
    font-weight: 600;
    margin:0 3px;
}
/*--------------------------------------------------------------
    6. Main Content
--------------------------------------------------------------*/
#main {
    margin-left: 250px;
    padding: 105px 30px 30px;
    transition: margin-left 0.3s ease-out;
}
#main.full-page-content {
    margin-left: 0;
}
.page-content .card.radiustop-0{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
@media screen and (max-width: 1199px) {
    #main {
        margin-left: 0;
        padding: 91px 16px 16px 16px;
    }
}
/*--------------------------------------------------------------
    7. Page Heading
--------------------------------------------------------------*/
.page-heading {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.25rem;
    height: auto;
    background-color: var(--white);
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
    border-bottom: 1px solid var(--border);
}

.page-heading h3 {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    color: var(--dark);
    padding-left: 1rem;
    margin-bottom: 0;
}
.page-heading h3::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 20px;
    background-color: var(--primary);
}
.page-heading h3 .btn-icon i{
    font-size: 1.75rem !important;
    color: var(--dark);
}
.page-heading.backbtn h3{
    padding-left: 0;
}
.page-heading.backbtn h3::before{
    content: unset;
}
.page-heading h3 span{
    color: var(--primary);
    margin-left: .25rem;
}
.page-title-headings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.page-title-headings h3 {
    margin-bottom: 0;
    margin-right: 1rem;
}
.page-heading-right{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.page-heading .breadcrumb {
    margin: 0;
}
.page-heading .breadcrumb .breadcrumb-item a {
    display: flex;
    align-items: center;
}
.page-heading .breadcrumb .breadcrumb-item.active {
    color: var(--primary);
}
.page-heading .page-heading-btn-group{
    display: flex;
    align-items: center;
    border: 1px solid var(--primary);
    border-radius: 50px;
    overflow: hidden;
}
.page-heading .page-heading-btn-group a{
    font-weight: 500;
    padding: 0.547rem 1rem;
    font-size: 15px;
    min-width: 110px;
    text-align: center;
}
.page-heading .page-heading-btn-group a.active{
    background-color: var(--primary);
    color: var(--font-white);
}
.custom-btn-add i{
    display: none;
}
@media (max-width: 768.98px) {
    .page-heading {
        padding: 1rem;
    }
    .custom-btn-add i{
        display: block;
        font-size: 20px;
    }
    .custom-btn-add span{
        display: none;
    }
}

@media (max-width: 575.98px) {
    .page-heading h3 {
        font-size: 22px;
    }
}
/*--------------------------------------------------------------
    8. Avatar
--------------------------------------------------------------*/
.avatar {
    display: inline-flex;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    position: relative;
}
.avatar .avatar-content {
    width: 32px;
    height: 32px;
    color: var(--font-white);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
}
.avatar .avatar-content i,
.avatar .avatar-content svg {
    color: var(--font-white);
    font-size: 1rem;
    height: 1rem;
}
.avatar img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}
.avatar .avatar-status {
    width: 0.7rem;
    height: 0.7rem;
    position: absolute;
    border-radius: 50%;
    border: 1px solid var(--border);
    bottom: 0;
    right: 0;
}
.avatar.avatar-sm .avatar-content,
.avatar.avatar-sm img {
    width: 24px;
    height: 24px;
    font-size: 0.8rem;
}
.avatar.avatar-md .avatar-content,
.avatar.avatar-md img {
    width: 32px;
    height: 32px;
    font-size: 0.8rem;
}
.avatar.avatar-lg .avatar-content,
.avatar.avatar-lg img {
    width: 48px;
    height: 48px;
    font-size: 1.2rem;
}
.avatar.avatar-xl .avatar-content,
.avatar.avatar-xl img {
    width: 60px;
    height: 60px;
    font-size: 1.4rem;
}
/*--------------------------------------------------------------
    9. Card
--------------------------------------------------------------*/
.card {
    border: none;
    border-radius: .625rem;
    background-color: var(--white);
}

.card-header {
    border-bottom: none;
    background-color: var(--white);
    border-top-left-radius: .625rem !important;
    border-top-right-radius: .625rem !important;
    padding: 1.25rem;
}
.card-body {
    padding: 1.25rem;
}
.card-body .row:last-child {
    border-bottom: unset !important;
}
.card-image {
    margin-bottom: .5rem;
    overflow: hidden;
    padding-top: 50.2%;
    position: relative;
    border-radius: .525rem;
}
.card-image img {
    border-radius: .652rem;
    display: block;
    height: 100%;
    left: 0;
    -o-object-position: center center;
    object-position: center center;
    position: absolute;
    top: 0;
    width: 100%;
    margin-bottom: 0.5rem;
}
.card-footer {
    padding: 1.25rem;
    background-color: var(--white);
    border-top-color:var(--border);
    border-radius:  0 0 .625rem .625rem !important;
}
@media (max-width: 400px) {
    .card-header, .card-body, .card-footer{
        padding: 1rem;
    }
}
/*--------------------------------------------------------------
    10. Table
--------------------------------------------------------------*/
tbody,
td,
tfoot,
th,
thead,
tr {
    vertical-align: middle;
    color: var(--dark);
}
.table-bg-primary.table thead th{
    background-color: var(--primary) !important;
    color: var(--font-white);
}
thead th{
    padding: .75rem .5rem !important;
    background-color: var(--light) !important;
}
tbody td {
    color: var(--gray);
    font-weight: 500;
}
.table-responsive {
    box-shadow: 0 0 0 1px var(--border2);
    border-radius: .625rem;
    background-color: var(--white);
}

.table-responsive table {
    margin-bottom: 0;
}

.table-responsive .table> :not(caption)>*>* {
    padding: .5rem;
}

.table-responsive .table-bordered> :not(caption)>* {
    border-width: 0;
}

.table-responsive .table> :not(:last-child)> :last-child>* {
    border-bottom-color: var(--border2);
}

.table-responsive .table th,
.table-responsive .table td {
    border: 1px solid var(--border2);
}

.table-responsive .table th:first-child,
.table-responsive .table td:first-child {
    border-left: none;
}

.table-responsive .table th:last-child,
.table-responsive .table td:last-child {
    border-right: none;
}

.table-responsive .table tr:first-child th {
    border-top: none;
}

.table-responsive .table tr:last-child td {
    border-bottom: none;
}
.modal-img img{
    width: 40px;
    height: 40px;
    border-radius: .625rem;
}
.table-sort-filter {
    display: flex;
    align-items: center;
}
.table-sort-filter .table-sort {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: .5rem;
}
.table-sort-filter .table-sort i {
    font-size: 10px;
    color: var(--dark);
    line-height: .8;
    cursor: pointer;
}
.table-sort-filter .table-sort i.active{
    color: var(--primary);
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: var(--white);
    color: var(--gray);
}
.table-striped > tbody > tr:nth-of-type(even) > * {
    --bs-table-accent-bg: var(--light);
    color: var(--gray);
}
/*--------------------------------------------------------------
    11. Forms
--------------------------------------------------------------*/
.form-label {
    font-size: 14.5px;
    color: var(--gray);
    font-weight: 500;
    margin-bottom: 0;
}
label.form-label .rerq {
    color: var(--danger);
    margin-left: 0.1rem;
}
label.error {
    display: none;
    font-size: .875em;
    color: var(--danger);
    background-color: #ea433512;
    border-radius: .525rem;
    padding: 0 .5rem;
    margin-bottom: .5rem;
}
.invalid-feedback strong {
    font-size: .875em;
    color: var(--danger);
    background-color: #ea433512;
    border-radius: .525rem;
    padding: .2rem .5rem;
    margin-bottom: .5rem;
    font-weight: 500 !important;
}
.form-control-lg {
    min-height: calc(1.7em + 1rem + 2px) !important;
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .625rem;
}
.form-control-sm {
    min-height: calc(1.858em + 0.5rem + 2px) !important;
    border-radius: .325rem;
}
.form-control,
.form-select,
.rs-picker-default .rs-picker-toggle {
    padding: 0.375rem 0.75rem;
    min-height: 40px;
    border-radius: .625rem;
    color: var(--dark);
    font-size: 15px;
    font-weight: 500;
    border-color: var(--border);
    background-color: var(--white) !important;
}
.dark .form-control,
.dark .form-select,
.dark .rs-picker-default .rs-picker-toggle{
    background-color: var(--light) !important;
}
.rs-picker.rs-picker-select.was-invalid .rs-picker-toggle{
    border-color: var(--danger) !important;
}
.input-group-text{
    border: none;
    background-color: var(--light);
    border-radius: .625rem;
}
.rs-picker-default .rs-picker-toggle.rs-btn {
    padding-top: 10px;
    padding-bottom: 10px;
}
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret{
    top: 10px;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active{
    border-color: #456dff80;
}
.rs-picker-toggle-active, .rs-picker.rs-picker-focused{
    box-shadow: unset;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value{
    color: var(--dark);
}
.rs-picker-menu{
    background-color: var(--white);
    border-radius: .625rem;
    z-index: 1060;
    padding: .5rem !important;
}
.rs-picker-select-menu-item{
    color: var(--dark);
    border-radius: .5rem;
    font-weight: 500;
    line-height: 1.2;
}
.rs-picker-select-menu-items{
    margin-bottom: 0;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input{
    color: var(--dark);
    background-color: var(--light);
    border-color: var(--border);
    border-radius: .625rem;
    font-size: 15px;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus, .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:hover:not(:disabled),
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus, .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:hover:not(:disabled){
    border-color: #456dff80;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus{
    outline: unset;
}
.rs-picker-select-menu-item:hover, .rs-picker-select-menu-item:focus, .rs-picker-select-menu-item.rs-picker-select-menu-item-focus{
    background-color: rgb(69 109 255 / 7%);
    color: var(--primary);
}
.rs-picker-select-menu-items .rs-picker-menu-group:not(:first-child) {
    border-top: 1px solid var(--border);
    border-top: 1px solid var(--border);
}
.rs-picker-select-menu-items .rs-picker-menu-group-title{
    color: var(--dark);
    font-weight: 600;
}
.form-select {
    padding: 0.375rem 2rem 0.375rem 0.75rem;
}
.form-control:focus,
.form-select:focus {
    border-color: #456dff80;
    outline: 0;
    box-shadow: unset;
    color: var(--dark);
    background-color: var(--white);
}
html.dark .form-control:disabled{
    background-color: #ffffff0d;
}
.form-control::-webkit-file-upload-button {
    height: 40px;
    color: var(--dark);
    background-color: var(--white);
    border: 4px solid var(--light);
    border-radius: .625rem;
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: var(--white);
}

.form-group[class*="has-icon-"].has-icon-left .form-control {
    padding-left: 3rem;
}

.form-group[class*="has-icon-"].has-icon-left .form-control-icon {
    left: 0;
}

.form-group[class*="has-icon-"].has-icon-right .form-control {
    padding-right: 3rem;
}

.form-group[class*="has-icon-"].has-icon-right .form-control-icon {
    right: 0;
}

.form-group[class*="has-icon-"] .form-control:focus~.form-control-icon i,
.form-group[class*="has-icon-"] .form-control:focus~.form-control-icon svg {
    color: var(--primary);
}

.form-group[class*="has-icon-"] .form-control.form-control-xl {
    padding-left: 3rem;
}

.form-group[class*="has-icon-"] .form-control.form-control-xl~.form-control-icon i {
    font-size: 1.6rem;
}

.form-group[class*="has-icon-"] .form-control.form-control-xl~.form-control-icon i:before {
    color: #a6a8aa;
}

.form-group[class*="has-icon-"] .form-control-icon {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 .8rem;
}

.form-group.form-control-lg-icon[class*="has-icon-"] .form-control-icon {
    top: 15px;
    transform: none;
}

.form-group[class*="has-icon-"] .form-control-icon i,
.form-group[class*="has-icon-"] .form-control-icon svg {
    width: 1.4rem;
    color: var(--gray);
    font-size: 1.4rem;
}
.inputgroup {
    position: relative;
    overflow: hidden;
}
.inputgroup .form-control{
    padding-right: 2.5rem;
}
.inputgroup i{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    color: var(--font-white);
    width: 44px;
    background-color: var(--primary);
    border-top-right-radius: .625rem;
    border-bottom-right-radius: .625rem;
    cursor: pointer;
}
.form-check {
    padding-left: 2em;
}

.form-check-input {
    width: 1.4em;
    height: 1.4em;
    margin-top: 0;
    background-color: var(--light);
    border: 1px solid var(--border);
}

.form-check .form-check-input {
    margin-left: -2em;
}

label.form-check-label {
    font-size: 15px;
    font-weight: 500;
    color: var(--gray);
}

.form-check-input:checked {
    background-color: var(--primary);
    border-color: var(--primary);
}
.form-switch .form-check-input{
    position: relative;
    width: 70px;
    height: 30px;
    border-radius: 50px;
    background-color: rgb(200, 200, 200);
    border: none;
}
.form-switch .form-check-input:checked{
    background-color: var(--primary);
}
.form-switch .form-check-input::after{
    content: "OFF";
    position: absolute;
    right: .75rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 13px;
    font-weight: 500;
    color: var(--font-white);
}
.form-switch .form-check-input:checked::after,
.form-switch.yes-no .form-check-input:checked::after{
    content: unset;
}
.form-switch .form-check-input:checked::before{
    content: "ON";
    position: absolute;
    left: .75rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 13px;
    font-weight: 500;
    color: var(--font-white);
}
.form-switch.yes-no .form-check-input::after{
    content: "NO";
}
.form-switch.yes-no .form-check-input:checked::before{
    content: "YES";
}
.form-switch .form-check-input{
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
}
/*--------------------------------------------------------------
    12. Button
--------------------------------------------------------------*/

.btn-primary {
    --bs-btn-color: var(--font-white);
    --bs-btn-bg: var(--primary);
    --bs-btn-border-color: var(--primary);
    --bs-btn-hover-color: var(--font-white);
    --bs-btn-hover-bg: var(--primary);
    --bs-btn-hover-border-color: var(--primary);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: var(--font-white);
    --bs-btn-active-bg: var(--primary);
    --bs-btn-active-border-color: var(--primary);
    --bs-btn-active-shadow: unset;
    --bs-btn-disabled-color: var(--font-white);
    --bs-btn-disabled-bg: var(--primary);
    --bs-btn-disabled-border-color: var(--primary);
}
.btn-light {
    --bs-btn-color: var(--dark);
    --bs-btn-bg: var(--light);
    --bs-btn-border-color: var(--light);
    --bs-btn-hover-color: var(--dark);
    --bs-btn-hover-bg: var(--light);
    --bs-btn-hover-border-color: var(--light);
    --bs-btn-focus-shadow-rgb: 211, 212, 213;
    --bs-btn-active-color: var(--dark);
    --bs-btn-active-bg: var(--light);
    --bs-btn-active-border-color: var(--light);
    --bs-btn-active-shadow: unset;
    --bs-btn-disabled-color: var(--dark);
    --bs-btn-disabled-bg: var(--light);
    --bs-btn-disabled-border-color: var(--light);
}
.btn-danger {
    --bs-btn-color: var(--font-white);
    --bs-btn-bg: var(--danger);
    --bs-btn-border-color: var(--danger);
    --bs-btn-hover-color: var(--font-white);
    --bs-btn-hover-bg: var(--danger);
    --bs-btn-hover-border-color: var(--danger);
    --bs-btn-focus-shadow-rgb: 225, 83, 97;
    --bs-btn-active-color: var(--font-white);
    --bs-btn-active-bg: var(--danger);
    --bs-btn-active-border-color: var(--danger);
    --bs-btn-active-shadow: unset;
    --bs-btn-disabled-color: var(--font-white);
    --bs-btn-disabled-bg: var(--danger);
    --bs-btn-disabled-border-color: var(--danger);
}
.btn-success {
    --bs-btn-color: var(--font-white);
    --bs-btn-bg: var(--success);
    --bs-btn-border-color: var(--success);
    --bs-btn-hover-color: var(--font-white);
    --bs-btn-hover-bg: var(--success);
    --bs-btn-hover-border-color: var(--success);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: var(--font-white);
    --bs-btn-active-bg: var(--success);
    --bs-btn-active-border-color: var(--success);
    --bs-btn-active-shadow: unset;
    --bs-btn-disabled-color: var(--font-white);
    --bs-btn-disabled-bg: var(--success);
    --bs-btn-disabled-border-color: var(--success);
}
.btn-info {
    --bs-btn-color: var(--font-white);
    --bs-btn-bg: var(--info);
    --bs-btn-border-color: var(--info);
    --bs-btn-hover-color: var(--font-white);
    --bs-btn-hover-bg: var(--info);
    --bs-btn-hover-border-color: var(--info);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: var(--font-white);
    --bs-btn-active-bg: var(--info);
    --bs-btn-active-border-color: var(--info);
    --bs-btn-active-shadow: unset;
    --bs-btn-disabled-color: var(--font-white);
    --bs-btn-disabled-bg: var(--info);
    --bs-btn-disabled-border-color: var(--info);
}
.btn-warning {
    --bs-btn-color: var(--font-white);
    --bs-btn-bg: var(--warning);
    --bs-btn-border-color: var(--warning);
    --bs-btn-hover-color: var(--font-white);
    --bs-btn-hover-bg: var(--warning);
    --bs-btn-hover-border-color: var(--warning);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: var(--font-white);
    --bs-btn-active-bg: var(--warning);
    --bs-btn-active-border-color: var(--warning);
    --bs-btn-active-shadow: unset;
    --bs-btn-disabled-color: var(--font-white);
    --bs-btn-disabled-bg: var(--warning);
    --bs-btn-disabled-border-color: var(--warning);
}
.btn-outline-primary {
    --bs-btn-color: var(--primary);
    --bs-btn-border-color: var(--primary);
    --bs-btn-hover-color: var(--font-white);
    --bs-btn-hover-bg: var(--primary);
    --bs-btn-hover-border-color: var(--primary);
    --bs-btn-focus-shadow-rgb: 13,202,240;
    --bs-btn-active-color: var(--font-white);
    --bs-btn-active-bg: var(--primary);
    --bs-btn-active-border-color: var(--primary);
    --bs-btn-active-shadow: unset;
    --bs-btn-disabled-color: var(--primary);
    --bs-btn-disabled-border-color: var(--primary);
}
.btn-outline-danger {
    --bs-btn-color: var(--danger);
    --bs-btn-border-color: var(--danger);
    --bs-btn-hover-color: var(--font-white);
    --bs-btn-hover-bg: var(--danger);
    --bs-btn-hover-border-color: var(--danger);
    --bs-btn-focus-shadow-rgb: 13,202,240;
    --bs-btn-active-color: var(--font-white);
    --bs-btn-active-bg: var(--danger);
    --bs-btn-active-border-color: var(--danger);
    --bs-btn-active-shadow: unset;
    --bs-btn-disabled-color: var(--danger);
    --bs-btn-disabled-border-color: var(--danger);
}
.btn-outline-success {
    --bs-btn-color: var(--success);
    --bs-btn-border-color: var(--success);
    --bs-btn-hover-color: var(--font-white);
    --bs-btn-hover-bg: var(--success);
    --bs-btn-hover-border-color: var(--success);
    --bs-btn-focus-shadow-rgb: 13,202,240;
    --bs-btn-active-color: var(--font-white);
    --bs-btn-active-bg: var(--success);
    --bs-btn-active-border-color: var(--success);
    --bs-btn-active-shadow: unset;
    --bs-btn-disabled-color: var(--success);
    --bs-btn-disabled-border-color: var(--success);
}
.btn-outline-warning {
    --bs-btn-color: var(--warning);
    --bs-btn-border-color: var(--warning);
    --bs-btn-hover-color: var(--font-white);
    --bs-btn-hover-bg: var(--warning);
    --bs-btn-hover-border-color: var(--warning);
    --bs-btn-focus-shadow-rgb: 13,202,240;
    --bs-btn-active-color: var(--font-white);
    --bs-btn-active-bg: var(--warning);
    --bs-btn-active-border-color: var(--warning);
    --bs-btn-active-shadow: unset;
    --bs-btn-disabled-color: var(--warning);
    --bs-btn-disabled-border-color: var(--warning);
}
.btn-outline-info {
    --bs-btn-color: var(--info);
    --bs-btn-border-color: var(--info);
    --bs-btn-hover-color: var(--font-white);
    --bs-btn-hover-bg: var(--info);
    --bs-btn-hover-border-color: var(--info);
    --bs-btn-focus-shadow-rgb: 13,202,240;
    --bs-btn-active-color: var(--font-white);
    --bs-btn-active-bg: var(--info);
    --bs-btn-active-border-color: var(--info);
    --bs-btn-active-shadow: unset;
    --bs-btn-disabled-color: var(--info);
    --bs-btn-disabled-border-color: var(--info);
}
.btn-link {
    --bs-btn-font-weight: 400;
    --bs-btn-color: var(--primary);
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color:var(--white);
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-color: var(--white);
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-border-color: transparent;
    --bs-btn-box-shadow: none;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    text-decoration: none;
}
.btn-transparent{
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-border-color: transparent;
    border-radius: 0 !important; 
}
.btn-transparent:hover,
.btn-transparent:active{
    background-color: transparent !important;
    border-color: transparent !important;
}
.btn-transparent.btn-primary{
    --bs-btn-color: var(--primary);
    --bs-btn-hover-color:var(--primary);
}
.btn-transparent.btn-primary:hover,
.btn-transparent.btn-primary:active{
    color: var(--primary) !important;
}
.btn-transparent.btn-danger{
    --bs-btn-color: var(--danger);
    --bs-btn-hover-color:var(--danger);
}
.btn-transparent.btn-danger:hover,
.btn-transparent.btn-danger:active{
    color: var(--danger) !important;
}
.btn-transparent.btn-success{
    --bs-btn-color: var(--success);
    --bs-btn-hover-color:var(--success);
}
.btn-transparent.btn-success:hover,
.btn-transparent.btn-success:active{
    color: var(--success) !important;
}
.btn-transparent i{
    font-size: 1.25rem !important;
}
.btn-group-lg>.btn,
.btn-lg {
    padding: 0.625rem 1.25rem !important;
    font-size: 1.25rem;
    border-radius: .625rem;
}

.btn-sm {
    padding: 0.407rem 0.5rem !important;
    font-size: 0.875rem;
    border-radius: .325rem;
}

.btn {
    font-weight: 500;
    border-radius: 0.625rem;
    padding: 0.485rem 1rem;
    font-size: 15px;
    box-shadow: unset;
}

.btn.btn-icon-text {
    display: flex;
    align-items: center;
}

.btn.btn-icon-text i {
    margin-right: .5rem;
    font-size: 1.225rem;
    line-height: 1.225rem;
}

.btn.btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    height: 32px;
    width: 32px;
}

.btn.btn-icon i {
    font-size: 1rem;
    line-height: 1rem;
}

.btn.btn-icon-lg {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    height: 40px;
    width: 40px;
}
.btn.btn-icon-lg i,
.btn.btn-icon-lg.bx{
    font-size: 1.15rem;
    line-height: 1.15rem;
}
@media (max-width: 767.98px) {
    .btn-group .btn {
        --bs-btn-padding-y: 0.25rem;
        --bs-btn-padding-x: 0.5rem;
        --bs-btn-font-size: 0.875rem;
        --bs-btn-border-radius: 0.25rem;
    }
}
/*--------------------------------------------------------------
    13. Switch Button 1
--------------------------------------------------------------*/
.switch.btn-sm {
    min-height: 36px !important;
}

.switch-on.btn.btn-sm {
    padding-right: 1.5rem !important;
    line-height: 22px;
}

.switch-off.btn.btn-sm {
    padding-left: 1.5rem !important;
    line-height: 22px;
}

.switch.btn.btn-light,
.switch.btn.btn-outline-light {
    border-color: var(--border) !important;
}

.switch-on.btn {
    padding-right: 2rem;
    line-height: 18px;
}

.switch-off.btn {
    padding-left: 2rem;
    line-height: 18px;
}
.switch-handle{
    background-color: var(--white) !important;
}
/*--------------------------------------------------------------
    14. Modal
--------------------------------------------------------------*/
.modal-content{
    background-color: var(--white);
    border-color: var(--border);
}
.modal-header{
    border-bottom-color: var(--border);
}
.modal .modal-header .modal-title{
    font-size: 20px;
    font-weight: 600;
}
.modal .modal-body .modal-content{
    border-color: transparent;
}
.modal .modal-footer{
    border-top-color: var(--border);
}
html.dark .btn-close{
    filter: invert(1);
}
/*--------------------------------------------------------------
    15. Login Screen
--------------------------------------------------------------*/
.auth-box-bg{
    background: url(../public/logo/loginbg.png);
    max-width: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
}
.auth {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 100vh;
}
.auth .auth-box {
    width: 500px;
    margin: 0 auto 150px;
}
.auth .auth-box .card,
.auth .auth-box .card-header{
    background-color: transparent;
}
.auth .auth-box .card-header{
    padding: 0;
}
.auth .auth-box .card-header .auth-logo{
    text-align: center;
    margin-bottom: 2.5rem;
}
.auth .auth-box .card-header .auth-logo .logo-mini{
   max-width: 100%;
   height: 60px;
}
html.dark .auth .auth-box .card-header .auth-logo .logo-mini path:last-child{
    fill: var(--font-white);
}
.auth .auth-box .card-body{
    padding: 2rem;
    background-color: var(--white);
    border-radius: 0.825rem;
    box-shadow: 0 12px 34px 0 rgb(0 0 0 / 6%);
}
.auth .auth-box .card-body .auth-title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: .75rem;
    text-align: center;
}
.auth .auth-box .card-body .auth-subtitle {
    font-size: 16px;
    color: var(--gray);
    margin-bottom: 2rem;
    text-align: center;
}
.auth .auth-box .card-body .form-control,
.auth .auth-box .card-body .form-check-input{
    background-color: var(--light2) !important;
}
.auth .auth-box .card-body .form-check-input:checked{
    background-color: var(--primary) !important
}
.auth .auth-box .input-prefix{
    position: relative;
}
.auth .auth-box .input-prefix .form-control{
    padding-left: 2.75rem;
}
.auth .auth-box .input-prefix.input-prefix-right .form-control{
    padding-right: 2.75rem;
}
.auth .auth-box .input-prefix i {
    position: absolute;
    left: 0.25rem;
    bottom: 0;
    font-size: 20px;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--gray);
}
.auth .auth-box .input-prefix.input-prefix-right .icon-prefix-right{
    left: unset;
    right: 0.25rem;
    color: var(--primary);
}
@media (max-width: 767px) {

}
@media (max-width: 400px) {
    .auth .auth-box .card-body{
        padding: 1rem;
    }
}
@media (max-width:375px) {

}
/*--------------------------------------------------------------
    16. Pagination
--------------------------------------------------------------*/
.pagination{
    --bs-pagination-color:#c0c3c7;
    --bs-pagination-padding-x: 0.75rem;
    --bs-pagination-padding-y: 0.375rem;
    --bs-pagination-font-size: 1rem;
    --bs-pagination-color: #c0c3c7;
    --bs-pagination-bg: #fff;
    --bs-pagination-border-width: 1px;
    --bs-pagination-border-color: #dee2e6;
    --bs-pagination-border-radius: 0.375rem;
    --bs-pagination-hover-color: #000;
    --bs-pagination-hover-bg: #e9ecef;
    --bs-pagination-hover-border-color: #dee2e6;
    --bs-pagination-focus-color: var(--bs-link-hover-color);
    --bs-pagination-focus-bg: #e9ecef;
    --bs-pagination-focus-box-shadow: 0 0 0 0.25remrgba(13, 110, 253, 0.25);
    --bs-pagination-active-color: #fff;
    --bs-pagination-active-bg: #0d6efd;
    --bs-pagination-active-border-color: #0d6efd;
    --bs-pagination-disabled-color: #6c757d;
    --bs-pagination-disabled-bg: #fff;
    --bs-pagination-disabled-border-color: #dee2e6;
    display: flex;
    padding-left: 0;
    list-style: none;
}
.page-item:nth-child(2) .page-link{
    background-color:var(--primary) !important;
}
.page-item:nth-child(2) .page-link:hover{
  color: #fff !important;
}
.pagination-custom{
    margin-top: 1.5rem;
}
.rc-pagination {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
}
.rc-pagination-item{
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    min-width: 40px;
    height: 40px;
    color: var(--gray);
    font-weight: 500;
    border-radius: 50px;
}
.rc-pagination-prev:before,
.rc-pagination-next:before{
    font-family: 'boxicons' !important;
    position: absolute;
    cursor: pointer;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: var(--white);
    border-radius: 50px;
}
.rc-pagination-next:before{
    content: "\ea50";
}
.rc-pagination-prev:before{
    content: "\ea4d";
}
.rc-pagination-item,
.rc-pagination-prev,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
    margin-right: 8px;
}
.rc-pagination-item.rc-pagination-item-active{
    background-color: var(--primary);
    color: var(--font-white);
}
.rc-pagination-item.rc-pagination-item-active a{
    color: var(--font-white);
}
.rc-pagination-prev.rc-pagination-disabled,
.rc-pagination-next.rc-pagination-disabled{
    color: var(--gray);
    opacity: 0.75;
}
.paggination-btn{
    min-width: 40px;
    height: 40px;
    overflow: hidden;
    background-color: transparent;
    color: var(--gray);
    border-radius: .625rem;
    border: transparent;
}
.rc-pagination-jump-prev button,
.rc-pagination-jump-next button {
    border-color: transparent;
    background-color: transparent;
}

.rc-pagination-jump-next button:after,
.rc-pagination-jump-prev button:after {
    display: block;
    content: "•••";
    font-size: 14px;
    color: var(--gray);
}
.rc-pagination-prev, .rc-pagination-next, .rc-pagination-item, .rc-pagination-jump-prev, .rc-pagination-jump-next {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 40px;
    color: var(--gray);
}

/*--------------------------------------------------------------
    17. Page Not Found
--------------------------------------------------------------*/
.error{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.no-found{
    text-align: center;
    padding: 3rem 0;
}
.no-found img{
    height: 200px;
}
.no-found p{
    font-size: 20px;
    font-weight: 600;
    margin-top: 2rem;
    margin-bottom: 0;
}
.Image-Map{
    width: 40px;
    height: 40px;
}

/*--------------------------------------------------------------
    18. Loader
--------------------------------------------------------------*/
.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-color:var(--white);
    z-index: 999;
}
/* .loader::before {
    content: "";
    position: absolute;
    background: linear-gradient(90deg,
      rgba(245, 245, 245, 0) 0%,
      rgba(245, 245, 245, 0.75) 50%,
      rgba(245, 245, 245, 0) 100%
    );
    height: 100%;
    width: 100%;
    z-index: 1;
    animation: skeleton 1.5s infinite;
}
.dark .loader::before {
    background: linear-gradient(90deg,
      rgba(20, 18, 24, 0) 0%,
      rgba(20, 18, 24, 0.5) 50%,
      rgba(20, 18, 24, 0) 100%
    );
} */
.loader:after {
    content: "";
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url('../public/logo/logomini.svg');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
}
.loader:before{
    content: "";
    position: fixed;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    border: 2px solid var(--primary);
    border-top-color: transparent;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    -webkit-animation: animate-preloader 1s linear infinite;
    animation: animate-preloader 1s linear infinite;
}
.loader.table-loader{
    position: absolute;
    border-radius: .625rem;
    min-height: calc(100vh - 227px);
}
.loader.table-loader:after,
.loader.table-loader:before{
    position: absolute;
}
@keyframes skeleton {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}
@-webkit-keyframes animate-preloader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate-preloader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
/*--------------------------------------------------------------
    19. Sweet Alert
--------------------------------------------------------------*/
.swal2-popup{
    background-color: var(--white) !important;
    color: var(--dark) !important;
    border-radius: .625rem !important;
}
/*--------------------------------------------------------------
    20. React Switch
--------------------------------------------------------------*/
.react-switch .react-switch-off,
.react-switch .react-switch-on{
    display: flex;
    justify-content: center;
    font-size: 13px;
    font-weight: 500;
    line-height: 30px;
    color: var(--font-white);
}
.react-switch .react-switch-off{
    margin-right: 4px;
}
.react-switch .react-switch-on{
    margin-left: 4px;
}
.react-switch.react-switch-small .react-switch-handle{
    box-shadow: unset !important;
    top: 3px !important;
    left: 2px;
    width: 18px !important;
    height: 18px !important;
}
/*--------------------------------------------------------------
    21. Darkmode
--------------------------------------------------------------*/
.darkmode-switch{
    position: relative;
    width: 40px;
    height: 40px;
    margin-right: 1.5rem;
    transition: background-color 0.3s ease-in-out;
}
.darkmode-switch #darkMode{
    border: none;
    background: transparent;
}
.darkmode-switch #darkMode::before{
    content: "\ee4e";
    font-family: boxicons!important;
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--gray);
    font-size:1.35rem;
}  
.darkmode-switch #darkMode.clicked::before {
    content: "\eebe";
}
/*--------------------------------------------------------------
    24. Multi Select Picker
--------------------------------------------------------------*/
.customMulSelect .react-select__control{
    padding: 0.1rem 0.25rem;
    min-height: 40px;
    border-radius: 0.625rem !important;
    color: var(--dark);
    font-weight: 500;
    background-color: var(--light) !important;
    border: none !important;
    box-shadow: unset !important;
}
.customMulSelect .react-select__control .react-select__indicator.react-select__dropdown-indicator svg{
    width: 16px;
    transition: transform 0.2s ease-out;
    color: hsl(0, 0%, 40%);
}
.customMulSelect .react-select__control.react-select__control--menu-is-open .react-select__indicator.react-select__dropdown-indicator svg{
    transform: rotate(180deg);
}
.react-select__indicator-separator{
    background-color: var(--border) !important;
}
.react-select__multi-value{
    background-color: var(--white) !important;
}
.react-select__multi-value__label{
    color: var(--dark) !important;
}
.react-select__menu{
    background-color: var(--white) !important;
    border-color: var(--border) !important;
    border-radius: .625rem !important;
    z-index: 9 !important;
}
.react-select__option{
    color: var(--dark);
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
}
.customMulSelect.rs-novalidated .react-select__control{
    border: 1px solid var(--danger) !important;
}
.react-select__input-container,
.react-select__single-value{
    color: var(--dark) !important;
}
.custom-select-menu .rs-picker-select .rs-picker-toggle-caret{
    display: none;
}
.custom-select-menu .rs-picker-select .rs-picker-toggle{
    padding: 8px 12px;
}
.custom-select-menu .rs-picker-select.pending .rs-picker-toggle{
    background-color: #CEEEE3;
}
.custom-select-menu .rs-picker-select.pending .rs-picker-toggle span{
    font-weight: 500;
    color: #08A975;
}
.custom-select-menu .rs-picker-select.running .rs-picker-toggle{
    background-color: #DAE2FF;
}
.custom-select-menu .rs-picker-select.running .rs-picker-toggle span{
    font-weight: 500;
    color: #456DFF;
}
.custom-select-menu .rs-picker-select.close .rs-picker-toggle{
    background-color: #fbe1e1;
}
.custom-select-menu .rs-picker-select.close .rs-picker-toggle span{
    font-weight: 500;
    color: #ec6969;
}
/*--------------------------------------------------------------
    22. Toastify toast
--------------------------------------------------------------*/
.Toastify__toast-theme--light{
    background-color: var(--white) !important;
    color: var(--dark) !important;
}
.Toastify__close-button--light{
    color: var(--dark) !important;
    opacity: 1 !important;
}
/*--------------------------------------------------------------
    22. RS Dropdown
--------------------------------------------------------------*/
.rs-dropdown-menu{
    background-color: var(--white);
    border-radius: .625rem;
    padding: .5rem !important;
    z-index: 1060 !important;
}
.rs-dropdown-menu .rs-dropdown-item{
    font-size: 15px;
    color: var(--dark);
    font-weight: 500;
    border-radius: .5rem;
}
.rs-dropdown-menu .rs-dropdown-item:hover{
    background-color: rgb(69 109 255 / 7%) !important;
    color: var(--primary) !important;
}
.rs-dropdown-item:focus-visible, .rs-dropdown-item.rs-dropdown-item-focus{
    background-color: rgb(69 109 255 / 7%) !important;
    color: var(--primary) !important;
}
/*--------------------------------------------------------------
    23. Dashboard
--------------------------------------------------------------*/
.vv-dashboard .card{
    margin-bottom: 1.5rem;
}
.vv-dashboard .card .card-body .counter{
    display: flex;
}
.vv-dashboard .card .card-body .counter .counter-media{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: var(--light);
    border-radius: .625rem;
    font-size: 24px;
}
.vv-dashboard .card .card-body .counter.orange .counter-media{
    background-color: #ffb05c33;
    color: #FFB15C;
}
.vv-dashboard .card .card-body .counter.pink .counter-media{
    background-color: #db73ff33;
    color: #DB73FF;
}
.vv-dashboard .card .card-body .counter.green .counter-media{
    background-color: #1fd9a333;
    color: #1FD9A3;
}
.vv-dashboard .card .card-body .counter.blue .counter-media{
    background-color: #6a9bf433;
    color: #6A9BF4;
}
.vv-dashboard .card .card-body .counter .counter-content{
    margin-left: 1.25rem;
}
.vv-dashboard .card .card-body .counter .counter-content h3{
    font-size: 24px;
    font-weight: 700;
    margin-bottom: .15rem;
}
.vv-dashboard .card .card-body .counter .counter-content p{
    font-size: 14px;
    font-weight: 500;
    color: var(--gray);
    margin-bottom: 0;
}
.vv-dashboard .card .card-body .chart-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}
.vv-dashboard .card .card-body .chart-title h4{
    font-size: 18px;
    font-weight: 700;
    margin: 0;
}
.vv-dashboard .card .card-body .attch-link .attch-link-title {
    display: flex;
    align-items: center;
}
.vv-dashboard .card .card-body .attch-link .attch-link-title i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    font-size: 20px;
}
.vv-dashboard .card .card-body .attch-link.green .attch-link-title i, 
.vv-dashboard .card .card-body .attch-link.green .attch-link-content ul li a:hover {
    color: #1FD9A3;
}
.vv-dashboard .card .card-body .attch-link.red .attch-link-title i, 
.vv-dashboard .card .card-body .attch-link.red .attch-link-content ul li a:hover {
    color: #FF5C84;
}
.vv-dashboard .card .card-body .attch-link .attch-link-title h4 {
    font-size: 18px;
    font-weight: 700;
    margin-left: 0.75rem;
    margin-bottom: 0;
}
.vv-dashboard .card .card-body .attch-link .attch-link-content ul {
    position: relative;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    margin-left: 12px;
}
.vv-dashboard .card .card-body .attch-link .attch-link-content ul:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: calc(100% - 12px);
    background-color: var(--border);
}
.vv-dashboard .card .card-body .attch-link .attch-link-content ul li {
    position: relative;
    padding-left: 24px;
    margin: 0.5rem 0;
}
.vv-dashboard .card .card-body .attch-link .attch-link-content ul li:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 15px;
    height: 1px;
    background-color: var(--border);
}
.vv-dashboard .card .card-body .attch-link .attch-link-content ul li a {
    font-weight: 500;
    color: var(--font-gray);
    transition: .3s ease;
}
.vv-dashboard .card .card-body .attch-link.green .attch-link-title i, 
.vv-dashboard .card .card-body .attch-link.green .attch-link-content ul li a:hover {
    color: #1FD9A3;
}
.vv-dashboard .card .card-body .attch-link.red .attch-link-title i,
.vv-dashboard .card .card-body .attch-link.red .attch-link-content ul li a:hover {
    color: #FF5C84;
}
/*--------------------------------------------------------------
    25. Popover
--------------------------------------------------------------*/
.popover{
    border-color: var(--border);
}
.popover-body{
    background-color: var(--white);
    color: var(--dark);
}
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after, .bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after, .bs-popover-left>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after, .bs-popover-right>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after, .bs-popover-bottom>.popover-arrow::after{
    border-top-color: var(--border);
}
/*--------------------------------------------------------------
    25. Project Grid List
--------------------------------------------------------------*/
.project-grid-list .card{
    margin-bottom: 1.5rem;
}
.project-grid-list .project-list-item {
    display: flex;
    align-items: center;
}
.project-grid-list .project-list-item:hover{
    color: unset;
}
.project-grid-list .project-list-item img{
    width: 50px;
    height: 50px;
    border-radius: .625rem;
    margin-right: 1rem;
}
.project-grid-list .project-list-item .project-list-content h6{
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 0.25rem;
}
.project-grid-list .project-list-item .project-list-content p{
    margin: 0;
}
/* ==================================================
    25. User Details Modal
================================================== */
.user-details-modal .modal-body .user-details {
    float: left;
    width: 100%;
}
.user-details-modal .modal-body .user-details p {
    position: relative;
    float: left;
    min-width: 30%;
    font-weight: 600;
}
.user-details-modal .modal-body .user-details p:after {
    content: ":";
    position: absolute;
    top: 0;
    right: 15px;
    color: var(--dark);
}
.user-details-modal .modal-body .user-details span {
    float: left;
    width: 70%;
}
/* ==================================================
    25. Date Range Picker
================================================== */
.daterangepicker {
    border-radius: .5rem;
    font-family: inherit;
    background-color: var(--white);
    border: unset;
    font-size: 16px;
    margin-top: 0;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
}
.daterangepicker.opensright:before,
.daterangepicker.opensright:after{
    content: unset;
}
.daterangepicker .ranges ul{
    padding: .5rem;
}
.daterangepicker .ranges li{
    font-size: 14px;
    line-height: normal;
    font-weight: 500;
    color: var(--dark);
    border-radius: .5rem;
}
.daterangepicker.show-ranges.ltr .drp-calendar.left{
    border-left-color: var(--border);
}
.daterangepicker .ranges li:hover {
    background-color: rgb(69 109 255 / 7%);
    color: var(--primary);
}
.daterangepicker .ranges li.active {
    background-color: var(--primary);
    color: var(--font-white);
}
.daterangepicker .calendar-table {
    background-color: var(--white);
    border-color: transparent;
}

.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
    color: var(--font-white);
    border-color: var(--dark);
}
.daterangepicker .drp-buttons .btn{
    font-size: 13px;
    padding: 0.407rem 0.75rem !important;
}
.daterangepicker .drp-buttons .btn.btn-default{
    color: var(--dark);
    background-color: var(--light);
}
.daterangepicker td.in-range{
    background-color: rgb(69 109 255 / 7%);
    color: var(--dark);
}
.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
    background-color: var(--white);
    color: var(--gray);
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    background-color: var(--primary);
    color: var(--font-white);
}

.daterangepicker .drp-buttons {
    border-color: var(--border);
}
.daterangepicker .drp-selected{
    font-size: 13px;
    font-weight: 500;
}
.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
    color: var(--dark);
    background-color: var(--light);
}
.daterangepicker .calendar-table th{
    border-radius: 0;
}
.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td{
    min-width: 32px;
    width: 32px;
    height: 32px;
    line-height: 0;
}
.daterangepicker .calendar-table td{
    font-size: 13px;
    font-weight: 500;
}
.daterangepicker td.start-date.end-date {
    border-radius: .525rem;
}
.daterangepicker td.start-date {
    border-radius: .525rem 0 0 .525rem;
}
.daterangepicker td.end-date {
    border-radius: 0 .525rem .525rem 0;
}
.daterangepicker:after{
    border-bottom: 6px solid var(--border) !important;
}
.daterangepicker:before {
    border-bottom: 7px solid var(--border);
}
.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect{
    background-color: var(--white);
    border-color: var(--border);
    border-radius: 0.325rem;
    color: var(--dark);
}
iframe#webpack-dev-server-client-overlay {
    display: none !important
}
/* ==================================================
    25. Dashboard
================================================== */
.vi-dashboard .countdata{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--light);
    border: 1px solid var(--border);
    border-radius: 1rem;
    padding: 1.25rem;
}
.vi-dashboard .countdata.blue{
    background-color: rgb(0 143 251 / 3%);
    border-color: #008FFB;   
}
.vi-dashboard .countdata.red{
    background-color: rgb(255 69 96 / 3%);
    border-color: #FF4560;   
}
.vi-dashboard .countdata.purple{
    background-color: rgb(119 93 208 / 3%);
    border-color: #775DD0;   
}
.vi-dashboard .countdata .countdata-content p{
    font-size: 1rem;
    font-weight: 500;
    color: var(--dark);
    margin-bottom: .25rem;
    text-transform: uppercase;
}
.vi-dashboard .countdata .countdata-content h3{
    font-size: 1.5rem;
    font-weight: 700;
    color: #2E96FF;
    margin-bottom: 1rem;
}
.vi-dashboard .countdata .countdata-content ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}
.vi-dashboard .countdata .countdata-content ul li{
    display: flex;
    align-items: center;
    margin-right: .5rem;
    border-right: 1px solid var(--border);
    padding-right: .5rem;
}
.vi-dashboard .countdata .countdata-content ul li:last-child{
    border-right: none;
    margin: 0;
    padding: 0;
}
.vi-dashboard .countdata .countdata-content ul li p{
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: normal;
    font-weight: 500;
    color: #6C737D;
    text-transform: capitalize;
    margin: 0;
}
.vi-dashboard .countdata .countdata-content ul li p span{
    display: flex;
    align-items: center;
    margin-right: .25rem;
}
.vi-dashboard .countdata .countdata-content ul li p span i{
    font-size: 18px;
    margin-right: .25rem;
}
.vi-dashboard .countdata .countdata-content ul li p .txtgreen{
    color: #08A975;
}
.vi-dashboard .countdata .countdata-content ul li p .txtred{
    color: #EB6262;
}
.vi-dashboard .countdata .countdata-chart{
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
}

.chart-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.chart-title h4{
    position: relative;
    font-size: 20px;
    font-weight: 600;
    color:var(--dark);
    padding-left: 1rem;
    margin: 0;
}
.chart-title h4::before{
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 20px;
    background-color: var(--primary);
}
.chart-title p{
    font-size: 14px;
    font-weight: 400;
    color: var(--gray);
    margin-top: .15rem;
    margin-bottom: 0;
}
.chart-title .chartfilter{
    display: flex;
    align-items: center;
}
.apexcharts-tooltip{
    background-color: #fff !important;
    border: 1px solid #DFEDFC !important;
    border-radius: 10px !important;
}
.apexcharts-text{
    font-weight: 500 !important;
}
.apexcharts-legend{
    padding: 0 !important;
}
.tooltipbox h6{
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    padding: 5px 10px;
    background-color: #EBF5FF;
    color: #24272C;
    border-bottom: 1px solid #DFEDFC;
}
.tooltipbox .tooltipbody{
    padding: 5px 10px;
}
.tooltipbox .tooltipbody p{
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #6C737D;
    margin: 0;
}
.tooltipbox .tooltipbody p .legend{
    display: flex;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background-color: var(--border);
    margin-right: 8px;
}
.tooltipbox .tooltipbody p .tooltiptext{
    color: #24272C;
}


.chartfilter .chartlegend{
    display: flex;
    align-items: center;
}
.chartfilter .chartlegend p{
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--gray);
    margin: 0;
    padding-left: 1rem;
}
.chartfilter .chartlegend p span{
    display: flex;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background-color: var(--border);
    margin-right: 8px;
}
.chartfilter .form-control, .chartfilter .form-select, .chartfilter .rs-picker-default .rs-picker-toggle{
    border-color: var(--border);
    min-height: 36px;
}
.chartfilter .rs-picker-default .rs-picker-toggle.rs-btn{
    padding-top: 7px;
    padding-bottom: 7px;
    min-height: 36px;
}
.chartfilter .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.chartfilter .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus {
    border-color: var(--border);
}
.chartfilter .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret{
    top: 7px;
}
/* ==================================================
    25. Challan View
================================================== */
.echallan-view{
    border: 1px solid var(--border);
    padding: 1.5rem;
    border-radius: .625rem;
}
.echallan-view .echallan-view-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border);
    padding-bottom: 1rem;
}
.echallan-view .echallan-view-header p{
    margin: 0;
    font-weight: 600;
}
.echallan-view .echallan-view-header .echallan-view-header-l{
    display: flex;
    align-items: center;
}
.echallan-view .echallan-view-header .echallan-view-header-l p{
    position: relative;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 18px;
}
.echallan-view .echallan-view-header .echallan-view-header-l p::before{
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 2px;
    height: 26px;
    background-color: var(--border);
}
.echallan-view .echallan-view-header .echallan-view-header-l span{
    color: var(--gray);
}
.echallan-view .echallan-view-header .echallan-view-header-l h6{
    margin: 0;
    padding-left: 6px;
}
.echallan-view.unpaid{
    border-color: rgba(235, 98, 98, 0.2);
}
.echallan-view.unpaid .echallan-view-header{
    border-bottom-color: rgba(235, 98, 98, 0.2);
}
.echallan-view.unpaid .echallan-view-header p{
    color: #EB6262;
}
.echallan-view.unpaid .echallan-view-header .echallan-view-header-l p::before{
    background-color: #EB6262;
}
.echallan-view.unpaid .red{
    color: #EB6262;
}
.echallan-view.paid{
    border-color: rgba(8, 169, 117, 0.2);
}
.echallan-view.paid .echallan-view-header{
    border-bottom-color: rgba(8, 169, 117, 0.2);
}
.echallan-view.paid .echallan-view-header p{
    color: #08A975;
}
.echallan-view.paid .echallan-view-header .echallan-view-header-l p::before{
    background-color: #08A975;
}
.echallan-view.paid .green{
    color: #08A975;
}
.echallan-view .echallan-view-body{
    padding-top: 1.5rem;
}
.echallan-view .echallan-view-body p{
    color: var(--gray);
    margin: 0;
}
.echallan-view .echallan-view-body span{
    color: var(--dark);
    font-weight: 600;
}
